import React from 'react';
import CookiePolicyEN from '../../components/CookiePolicy/CookiePolicyEN';

const loadPageByCountry = () => {
  let component = <CookiePolicyEN />;
  return component;
};

const CookiePolicyPage = () => <>{loadPageByCountry()}</>;

CookiePolicyPage.layoutType = 'fullWidth';
CookiePolicyPage.public = true;

export default CookiePolicyPage;
