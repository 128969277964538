import React from 'react';
import styles from './policy.modules.scss';

const CookiePolicyEN = () => {
  return (
    <>
      <section className={styles.container}>
        <div className={styles.heading}>
          <strong>Cookie Policy</strong>
        </div>
        <div className={styles['policy-content']}>
          <strong className={styles.heading__font}>Cookie Policy</strong>
          <p>June 2022</p>
          <p>
            Our website uses cookies to distinguish you from other users of our
            website. This helps us to provide you with a good experience when
            you browse our website and also allows us to improve our site.
          </p>
          <p>
            <strong className={styles.heading__font}>
              Manage your cookie settings
            </strong>
          </p>
          <p>
            Cookie Settings on this website are managed by a third-party
            plug-in. Please refresh the page if you click the &quot;Cookie
            Settings&quot; button below but the Privacy Preference Center window
            does not appear.
          </p>
          <p>
            <button
              id="ot-sdk-btn"
              className="ot-sdk-show-settings"
              type="button"
            >
              Cookies Settings
            </button>
          </p>
          <p>
            {' '}
            <br />{' '}
          </p>
          <p>
            <strong className={styles.heading__font}>Cookie List</strong>
          </p>
          <p>
            Information in this section is managed by a third-party plug-in.
            Please refresh the page if you don&#8216;t see information appearing
            in this section.
          </p>
          <p>
            A cookie is a small piece of data (text file) that a website – when
            visited by a user – asks your browser to store on your device in
            order to remember information about you, such as your language
            preference or login information. Those cookies are set by us and
            called first-party cookies. We also use third-party cookies – which
            are cookies from a domain different than the domain of the website
            you are visiting – for our advertising and marketing efforts. More
            specifically, we use cookies and other tracking technologies for the
            following purposes:
          </p>
          <p>
            <strong>Strictly Necessary Cookies</strong>
          </p>
          <p>
            These cookies are necessary for the website to function and cannot
            be switched off in our systems. They are usually only set in
            response to actions made by you which amount to a request for
            services, such as setting your privacy preferences, logging in or
            filling in forms. You can set your browser to block or alert you
            about these cookies, but some parts of the site will not then work.
            These cookies do not store any personally identifiable information.
          </p>
          <p>
            <table className={styles.contactWrapper}>
              <tr>
                <td>
                  <strong>Cookie Subgroup</strong>
                </td>
                <td>
                  <strong>Cookies</strong>
                </td>
                <td>
                  <strong>Cookies used</strong>
                </td>
              </tr>
              <tr>
                <td>uat-th.careplus.co</td>
                <td>XSRF-TOKEN</td>
                <td>First Party</td>
              </tr>
              <tr>
                <td>uat-th.careplus.co</td>
                <td>OptanonConsent, OptanonAlertBoxClosed</td>
                <td>First Party</td>
              </tr>
            </table>
          </p>
        </div>
      </section>
    </>
  );
};

export default CookiePolicyEN;
